import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Loading, Popup, Row, Text, } from '@commonsku/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useIdentity } from '../../hooks';
import { updateNote } from '../../redux/notes';
import { DashboardTile, LabeledSelect, MarketingTile, rebuildTooltip } from '../helpers';
import { AddTaskFormPopup, NoteSidepanel } from '../message';
import MultiViewsCalendar from '../calendar/MultiViewsCalendar';
import DashboardPageTitle from './DashboardPageTitle';
import { CALENDAR_VIEWS } from '../calendar/utils';
import { getPurchaseOrders } from '../../selectors';
import { getIdentityUtils, oauth } from '../../utils';
import POSidePanel from '../production-report/POSidePanel';
import getUsers from '../calendar/getUsers';
import ProductionReportView from '../ProductionReportView';
import ProjectsTableWindowed from './ProjectsTableWindowed';
import {
  getRequestDataByReport,
  REPORT_TYPE,
  selectors as reportsSelectors,
} from '../../redux/reports';
import TilesSlider from '../helpers/TilesSlider';
import DashboardNumberTile from './DashboardNumberTile';
import WelcomeDashboardTile from './WelcomeDashboardTile';
import {
  POsByStatusChart,
} from './charts';
import { parseReports } from './utils';
import { NewProjectPopupButton } from '../project/NewProjectPopup';
import { getUserRepOptions } from '../../selectors/users';

const report_types = [
  'PO_STATUS_TOTALS',
  'OPEN_ORDER_STATUS_TOTALS',
];
const report_sub_types = {
  'PO_BY_STAGE': 'PO_BY_STAGE',
  'PO_BY_PROOF_STATUS': 'PO_BY_PROOF_STATUS',
  'PO_OVERDUE_NEXT_ACTION_DATE': 'PO_OVERDUE_NEXT_ACTION_DATE',
  'PO_OVERDUE_IN_HANDS_DATE': 'PO_OVERDUE_IN_HANDS_DATE',
  'PO_OVERDUE_PROJ_SHIP_DATE': 'PO_OVERDUE_PROJ_SHIP_DATE',
  'PO_PROBLEM_STATUS': 'PO_PROBLEM_STATUS',
  'PO_FOLLOW_UP_STATUS': 'PO_FOLLOW_UP_STATUS',
};

function Dashboard({ ...props }) {
  const dispatch = useDispatch();
  const identity = useIdentity();

  const reportsLoading = useSelector(reportsSelectors.loading);
  const isLoading = useSelector(s => {
    const rLoading = reportsSelectors.loading(s);
    return s.display.loading.fetch_pos_for_production
     || report_types.filter(
        v => _.get(rLoading, v, false)
      ).length > 0;
  });
  const statuses = useSelector(s => s.entities.statuses);
  const reports = useSelector(reportsSelectors.reports);
  const pos = useSelector(getPurchaseOrders);
  const reps_options = useSelector(getUserRepOptions);

  const [selectedTaskId, setSelectedTaskId] = useState(false);
  const [showPageLoading, setShowPageLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(null);
  const [selectedPOId, setSelectedPOId] = useState(false);
  const [selectedPOTab, setSelectedPOTab] = useState('details');
  const [repId, setRepId] = useState(identity.user_id);
  const [marketingTile, setMarketingTile] = useState({
    imgSrc: '',
    title: '',
    text: '',
    link: '',
    dashboard: '',
  });

  const selectedPO = _.get(pos, [selectedPOId], false);
  const selectedRepIds = useSelector(s => getUsers(s, null, repId));

  useEffect(() => {
    oauth('GET', 'marketing-setting/dashboard-marketing', {dashboard_type: 'PRODUCTION'})
      .then(resp => {
        const { json } = resp;
        setMarketingTile(json.marketing);
      })
      .catch(err => {
        console.error(err);
      });
  }, [dispatch, identity]);

  useEffect(() => {
    setShowPageLoading(true);
    rebuildTooltip();
    const reqs = Object.values(REPORT_TYPE)
      .filter(v => v.type === 'OPEN_ORDER_STATUS_TOTALS')
      .reduce((acc, v) => {
        const reqData = getRequestDataByReport(v.type, {});
        if (!reqData) { return acc; }
        return [...acc, reqData];
      }, []);

    (async () => {
      await Promise.all(reqs.map((v) => dispatch(
        v.cb(v.route, v.type, {
          ...v.params,
          ...(v.type === 'OPEN_ORDER_STATUS_TOTALS' ? {
            order_type: 'SALES ORDER',
            rep_type: 'ORDER',
            rep_id: '',
            production_rep_id: '',
            status_name: 'Client Approved',
          } : {}),
        })
      )));
      setShowPageLoading(false);
    })();
  }, [dispatch]);

  useEffect(() => {
    setShowPageLoading(true);
    rebuildTooltip();
    const reqs = Object.values(REPORT_TYPE)
      .filter(v => report_types.includes(v.type)
        && v.type !== 'OPEN_ORDER_STATUS_TOTALS')
      .reduce((acc, v) => {
        if (v.type === 'PO_STATUS_TOTALS') {
          return [
            ...acc,
            ...Object.keys(report_sub_types)
              .filter(r => ![
                'PO_PROBLEM_STATUS',
                'PO_FOLLOW_UP_STATUS',
              ].includes(r))
              .map(
                r => getRequestDataByReport(
                  v.type,
                  { rep_id: repId, report_sub_type: r }
                )
              )
          ];
        }
        const reqData = getRequestDataByReport(v.type, { rep_id: repId });
        if (!reqData) { return acc; }
        return [...acc, reqData];
      }, []);

    (async () => {
      await Promise.all(reqs.map((v) => dispatch(
        v.cb(v.route, v.type, v.params)
      )));
      setShowPageLoading(false);
    })();
  }, [dispatch, repId]);

  const onUpdateTask = (note) => {
    dispatch(updateNote(note.note_id, note));
  };

  function renderFilters() {
    return (
      <Row style={{ marginTop: 0, }}>
        <Col xs md={4} padded>
          <LabeledSelect
            name="production-rep"
            options={reps_options}
            value={repId}
            onChange={v => {
              const val = _.get(v, 'value', '');
              setRepId(val);
            }}
            label="Production Rep"
            className="csku-select-v3"
            isClearable={repId}
          />
        </Col>
      </Row>
    );
  }

  const getStatusIdByName = React.useCallback((status_name, checkProduction=false) => {
    if (!status_name) { return ''; }
    return _.get(
      _.find(
        _.values(statuses),
        s => {
          const result = s.status_name === status_name;
          if (checkProduction) {
            return result && [
              'PROOF', 'PURCHASE ORDER'
            ].includes(s.parent_type);
          }
          return result;
        }
      ),
      ['status_id'],
      ''
    ) || '';
  }, [statuses]);

  const getProductionFilter = React.useCallback((report_sub_type, status_id) => {
    const productionFilter = {
      production_rep_ids: selectedRepIds
    };
    if (report_sub_type === report_sub_types.PO_BY_STAGE) {
      productionFilter['status_id'] = status_id;
    } else if (report_sub_type === report_sub_types.PO_BY_PROOF_STATUS) {
      productionFilter['proof_status_id'] = status_id;
    } else if (report_sub_type === report_sub_types.PO_PROBLEM_STATUS) {
      productionFilter['standing'] = 'PROBLEM';
    } else if (report_sub_type === report_sub_types.PO_FOLLOW_UP_STATUS) {
      productionFilter['standing'] = 'FOLLOWUP';
    } else if (report_sub_type === report_sub_types.PO_OVERDUE_NEXT_ACTION_DATE) {
      productionFilter['next_action_date_overdue'] = true;
    } else if (report_sub_type === report_sub_types.PO_OVERDUE_IN_HANDS_DATE) {
      productionFilter['inhands_date_overdue'] = true;
    } else if (report_sub_type === report_sub_types.PO_OVERDUE_PROJ_SHIP_DATE) {
      productionFilter['proj_ship_date_overdue'] = true;
    }

    return productionFilter;
  }, [selectedRepIds]);

  const onClickNumberTile = React.useCallback(v => () => {
    const report_sub_type = v.report_sub_type || '';
    const reqData = getRequestDataByReport(v.report_type, {
      rep_id: repId,
      production_rep_id: repId,
    });
    if (!reqData) { return; }
    setShowPageLoading(false);
    dispatch(reqData.cb(reqData.route, reqData.type, {
      ...reqData.params,
      report_type: v.report_type,
      report_sub_type: report_sub_type,
      get_data: 1,
      ...v.params
    }));

    let status_name = '';
    if (v.report_sub_type === report_sub_types.PO_FOLLOW_UP_STATUS) {
      status_name = 'Follow Up';
    } else if (v.report_sub_type === report_sub_types.PO_PROBLEM_STATUS) {
      status_name = 'Problem';
    }

    const status_id = getStatusIdByName(status_name, v.type === 'production-list');
    const title = `${v.type === 'project-list' ? 'Sales Orders' : 'POs'} - ${v.text}`;
    const listType = v.type || 'production-list';

    setShowPopup({
      type: listType,
      title: title,
      report_type: v.report_type,
      report_sub_type: report_sub_type,
      loading: v.loading || _.get(reportsLoading, [v.report_type], false),
      storeLoadingKeys: ['reports', 'loading', v.report_type],
      posFilters: listType === 'production-list'
        ? getProductionFilter(report_sub_type, status_id)
        : undefined,
    });
  }, [dispatch, repId, reportsLoading, getProductionFilter, getStatusIdByName]);

  const onClickPOStatusChartColumn = React.useCallback((data) => {
    if (
      !_.get(data, 'order_type', '')
      && !_.get(data, 'status_name', '')
    ) {
      return;
    }

    setShowPageLoading(false);
    const order_type = data.order_type;
    const status_name = data.status_name;
    const report_sub_type = data.report_type || '';
    const status_id = getStatusIdByName(status_name, true);
    const title = `POs - ${status_name}`;
    const reqData = getRequestDataByReport('PO_STATUS_TOTALS', {
      rep_id: repId,
      production_rep_id: repId,
    });
    if (!reqData) { return; }
    dispatch(reqData.cb(reqData.route, reqData.type, {
      ...reqData.params,
      report_type: 'PO_STATUS_TOTALS',
      report_sub_type: report_sub_type,
      get_data: 1,
      order_type,
      status_name,
      status_id,
    }));

    setShowPopup({
      type: 'production-list',
      title: title,
      report_type: 'PO_STATUS_TOTALS',
      report_sub_type: report_sub_type,
      order_type: order_type,
      storeLoadingKeys: ['reports', 'loading', 'PO_STATUS_TOTALS'],
      posFilters: getProductionFilter(report_sub_type, status_id),
    });
  }, [dispatch, repId, getProductionFilter, getStatusIdByName]);

  const salesReports = parseReports(reports);
  const tiles = React.useMemo(() => {
    const nextActionOverdueTotals = parseInt(_.get(
      salesReports.poStatusReport,
      ['totals', report_sub_types.PO_OVERDUE_NEXT_ACTION_DATE],
      0
    ) || 0);
    const inhandsOverdueTotals = parseInt(_.get(
      salesReports.poStatusReport,
      ['totals', report_sub_types.PO_OVERDUE_IN_HANDS_DATE],
      0
    ) || 0);
    const projShipOverdueTotals = parseInt(_.get(
      salesReports.poStatusReport,
      ['totals', report_sub_types.PO_OVERDUE_PROJ_SHIP_DATE],
      0
    ) || 0);
    const problemStatusTotals = parseInt(_.get(
      salesReports.poStatusReport,
      ['totals', 'num_problem'],
      0
    ) || 0);
    const followupStatusTotals = parseInt(_.get(
      salesReports.poStatusReport,
      ['totals', 'num_follow_up'],
      0
    ) || 0);
    const soClientApproved = parseInt(_.get(
      salesReports.openOrderStatusTotals,
      ['sales_order_num_client_approved']
    ) || 0);

    return [
      {
        clickable: nextActionOverdueTotals > 0,
        checked: nextActionOverdueTotals === 0,
        showWarning: nextActionOverdueTotals > 0,
        total: nextActionOverdueTotals,
        type: 'production-list',
        title: 'POs',
        text: nextActionOverdueTotals === 0
          ? 'No overdue next actions'
          : 'Next action overdue',
        report_type: REPORT_TYPE.PO_STATUS_TOTALS.type,
        report_sub_type: report_sub_types.PO_OVERDUE_NEXT_ACTION_DATE,
        params: {
          report_type: REPORT_TYPE.PO_STATUS_TOTALS.type,
          report_sub_type: report_sub_types.PO_OVERDUE_NEXT_ACTION_DATE,
          rep_id: repId,
          production_rep_id: repId,
        },
        totalStyle: {
          color: nextActionOverdueTotals === 0 ? '#DADADA' : '#000000',
        },
      },
      {
        clickable: inhandsOverdueTotals > 0,
        checked: inhandsOverdueTotals === 0,
        showWarning: inhandsOverdueTotals > 0,
        total: inhandsOverdueTotals,
        type: 'production-list',
        title: 'POs',
        text: inhandsOverdueTotals === 0
          ? 'No overdue in-hands dates'
          : 'In-hands overdue',
        report_type: REPORT_TYPE.PO_STATUS_TOTALS.type,
        report_sub_type: report_sub_types.PO_OVERDUE_IN_HANDS_DATE,
        params: {
          report_type: REPORT_TYPE.PO_STATUS_TOTALS.type,
          report_sub_type: report_sub_types.PO_OVERDUE_IN_HANDS_DATE,
          rep_id: repId,
          production_rep_id: repId,
        },
        totalStyle: {
          color: inhandsOverdueTotals === 0 ? '#DADADA' : '#000000',
        },
      },
      {
        clickable: projShipOverdueTotals > 0,
        checked: projShipOverdueTotals === 0,
        showWarning: projShipOverdueTotals > 0,
        total: projShipOverdueTotals,
        type: 'production-list',
        title: 'POs',
        text: projShipOverdueTotals === 0
          ? 'No overdue projected ship dates'
          : 'Projected ship date overdue',
        report_type: REPORT_TYPE.PO_STATUS_TOTALS.type,
        report_sub_type: report_sub_types.PO_OVERDUE_PROJ_SHIP_DATE,
        params: {
          report_type: REPORT_TYPE.PO_STATUS_TOTALS.type,
          report_sub_type: report_sub_types.PO_OVERDUE_PROJ_SHIP_DATE,
          rep_id: repId,
          production_rep_id: repId,
        },
        totalStyle: {
          color: projShipOverdueTotals === 0 ? '#DADADA' : '#000000',
        },
      },
      {
        clickable: problemStatusTotals > 0,
        showWarning: problemStatusTotals > 0,
        total: problemStatusTotals,
        type: 'production-list',
        title: 'POs',
        text: 'Problem status',
        report_type: REPORT_TYPE.PO_STATUS_TOTALS.type,
        report_sub_type: report_sub_types.PO_PROBLEM_STATUS,
        params: {
          report_type: REPORT_TYPE.PO_STATUS_TOTALS.type,
          report_sub_type: report_sub_types.PO_PROBLEM_STATUS,
          rep_id: repId,
          production_rep_id: repId,
        },
        totalStyle: {
          color: problemStatusTotals === 0 ? '#DADADA' : '#000000',
        },
      },
      {
        clickable: followupStatusTotals > 0,
        showWarning: followupStatusTotals > 0,
        total: followupStatusTotals,
        type: 'production-list',
        title: 'POs',
        text: 'Followup status',
        report_type: REPORT_TYPE.PO_STATUS_TOTALS.type,
        report_sub_type: report_sub_types.PO_FOLLOW_UP_STATUS,
        params: {
          report_type: REPORT_TYPE.PO_STATUS_TOTALS.type,
          report_sub_type: report_sub_types.PO_FOLLOW_UP_STATUS,
          rep_id: repId,
          production_rep_id: repId,
        },
        totalStyle: {
          color: followupStatusTotals === 0 ? '#DADADA' : '#000000',
        },
      },
      {
        clickable: true,
        showWarning: soClientApproved > 0,
        total: soClientApproved,
        title: 'Sales Orders',
        text: 'Client approved',
        type: 'projects-list',
        report_type: REPORT_TYPE.OPEN_ORDER_STATUS_TOTALS.type,
        params: {
          order_type: 'SALES ORDER',
          status_name: 'Client Approved',
          rep_type: '',
          rep_id: '',
          production_rep_id: '',
        },
        totalStyle: {
          color: soClientApproved === 0 ? '#DADADA' : '#000000',
        },
      },
    ];
  }, [salesReports, repId, reportsLoading]);

  const renderContent =  React.useCallback(() => {
    return (
      <Row>
        <Col xs sm={6} md={4} lg={2} padded>
          <DashboardNumberTile
            showPS
            style={{ height: 170, minWidth: 160, }}
            text={'With PS order status updated'}
            title={'POs'}
            total={'Coming Soon'}
            totalStyle={{
              fontSize: 18,
            }}
          />
        </Col>
        <Col xs sm={6} md={4} lg={2} padded>
          <DashboardNumberTile
            showPS
            style={{ height: 170, minWidth: 160, }}
            text={'With PS shipping update'}
            title={'POs'}
            total={'Coming Soon'}
            totalStyle={{
              fontSize: 18,
            }}
          />
        </Col>
        <Col xs sm={6} md={4} lg={2} padded>
          <DashboardNumberTile
            showPS
            style={{ height: 170, minWidth: 160, }}
            text={'TBD'}
            title={'POs'}
            total={'Coming Soon'}
            totalStyle={{
              fontSize: 18,
            }}
          />
        </Col>
        <Col xs sm={6} md={4} lg={2} padded>
          <WelcomeDashboardTile />
        </Col>
        <Col xs sm md={8} lg={4} style={{
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
          alignSelf: 'center',
        }}>
          <MarketingTile
            link={marketingTile.link}
            title={marketingTile.title}
            imgSrc={marketingTile.imgSrc}
            boxShadow={''}
            maxHeight={170}
          />
        </Col>

        <Col padded xs lg={6}>
          <DashboardTile>
            <Text as="p" style={{ marginTop: 0, fontSize: 16, fontWeight: 'bold', padding: 16 }}>
              Purchase Orders by Stage
            </Text>
            <POsByStatusChart
              totals={_.get(salesReports, ['poStatusReport', 'totals'], {})}
              order_type={'PURCHASE ORDER'}
              report_type={report_sub_types.PO_BY_STAGE}
              onClickColumn={onClickPOStatusChartColumn}
              height={"380px"}
            />
          </DashboardTile>
        </Col>

        <Col padded xs lg={6}>
          <DashboardTile>
            <Text as="p" style={{ marginTop: 0, fontSize: 16, fontWeight: 'bold', padding: 16 }}>
              Purchase Orders by Proof Status
            </Text>
            <POsByStatusChart
              totals={_.get(salesReports, ['poStatusReport', 'totals'], {})}
              order_type={'PROOF'}
              report_type={report_sub_types.PO_BY_PROOF_STATUS}
              onClickColumn={onClickPOStatusChartColumn}
              height={"380px"}
            />
          </DashboardTile>
        </Col>

        <Col xs>
          <TilesSlider deps={[tiles, identity]}>
            {tiles.map(v => {
              return (
                <Col xs sm={4} lg={3} padded
                  key={v.report_type + v.report_sub_type}
                  style={{ textAlign: 'center', height: 185 }}
                >
                  <DashboardNumberTile
                    style={{ height: 170, minWidth: 160, }}
                    {...v}
                    {...(v.clickable ? { onClick: onClickNumberTile(v) } : {})}
                  />
                </Col>
              );
            })}
          </TilesSlider>
        </Col>
      </Row>
    );
  }, [
    identity,
    marketingTile,
    onClickNumberTile,
    onClickPOStatusChartColumn,
    salesReports,
    tiles
  ]);

  function renderPopup() {
    if (!showPopup) { return null; }

    if (showPopup.action === 'add_task') {
      return (
        <AddTaskFormPopup onClose={() => setShowPopup(false)} message={{
          reminder_user: repId,
          details_type: 'NOTE',
          note_type: 'NOTE',
        }} />
      );
    }

    if (!['production-list', 'projects-list'].includes(showPopup.type)) {
      return null;
    }

    let Child = null;
    if (showPopup.type === 'production-list') {
      Child = <ProductionReportView
        {...showPopup}
        sidePanelRow={selectedPO}
        setSidePanelRow={(po) => {
          if (!po || !_.get(po, ['purchase_order_id'], false)) {
            setSelectedPOId(false);
            return;
          }
          setSelectedPOId(po.purchase_order_id);
        }}
        setSelectedSidePanelTab={setSelectedPOTab}
        showPopupTable
        onSave={() => setShowPopup(false)}
      />;
    } else if (showPopup.type === 'projects-list') {
      Child = <ProjectsTableWindowed
        {...showPopup}
        onSave={() => setShowPopup(false)}
      />;
    }

    return (
      <Popup
        title={showPopup.title}
        onClose={() => {
          setShowPopup(false);
        }}
        className={`popup ${showPopup.type}-popup csku-styles`}
      >{Child}<br /></Popup>
    );
  }

  return (<Row>
    <Col xs padded>
      <NoteSidepanel
        noteId={selectedTaskId}
        clearnoteId={() => setSelectedTaskId(false)}
        onUpdateNote={onUpdateTask}
      />
      <POSidePanel
        sidePanelRow={selectedPO}
        setSidePanelRow={setSelectedPOId}
        selectedTab={selectedPOTab}
        setSelectedTab={setSelectedPOTab}
        sidepanelZIndex={1005}
      />
      {renderPopup()}
      <Row style={{ marginTop: 0, marginBottom: 20, }}>
        <Col xs sm={6} padded>
          <DashboardPageTitle title={"Production Dashboard"} />
        </Col>
        <Col xs
          sm={6}
          padded
          end={1}
        ><NewProjectPopupButton /></Col>
      </Row>
      {renderFilters()}
      {isLoading && showPageLoading
        ? <>
          <Loading />
          <p style={{
            padding: 0,
            margin: 0,
            textAlign: 'center',
            color: '#00A0B6',
            fontSize: '1.3rem',
          }}>Loading...</p>
        </>
        : renderContent()}
      <Row style={{...(isLoading && showPageLoading ? {display: 'none'} : {})}}>
        <Col xs padded>
          <DashboardTile padded>
            <MultiViewsCalendar
              itemId={selectedTaskId}
              userId={repId}
              repType={'ORDER'}
              onClickItem={(viewType, objId, obj) => {
                if (viewType === CALENDAR_VIEWS.TASK) {
                  setSelectedTaskId(objId);
                } else if (viewType === CALENDAR_VIEWS.PO) {
                  setSelectedPOId(objId);
                }
              }}
              onAddTask={e => {
                setShowPopup({action: 'add_task'});
              }}
            />
          </DashboardTile>
        </Col>
      </Row>
    </Col>
  </Row>);
}

export default Dashboard;
